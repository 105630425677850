/** @jsx jsx */
import { jsx, Text, Divider, Image, Flex, Box } from "theme-ui"
import useSiteMetadata from "../hooks/use-site-metadata"

type PostFooterProps = {
  post: {
    slug: string
    title: string
    date: string
    tags?: {
      name: string
      slug: string
    }[]
    description?: string
    canonicalUrl?: string
    body: string
    excerpt: string
    timeToRead?: number
    banner?: {
      childImageSharp: {
        resize: {
          src: string
        }
      }
    }
  }
}

const mySource = "/portrait.jpg";

const PostFooter = ({ post }: PostFooterProps) => {
  const year = post.date.slice(-4);
  const { siteTitle } = useSiteMetadata()
  return(
    <div sx={{
      textAlign: `inherit`,
      color: `secondary`,
    }}>
      <Divider sx={{
        borderTopStyle: `solid`,
        backgroundImage: `linear-gradient(90deg,#fc5c7d,#6a82fb)`,
        borderColor: `rgba(0,0,0,0)`,
      }}/>
        <Flex sx={{ alignItems: "center", justifyContent: "center", columnGap: 32 }}>
          <Box p={0} sx={{margin: 0, marginLeft: 32 }}>
          <Image src={mySource} sx={{ 
            width: 64,
            borderRadius: "50%"
          }} />
          </Box>
          <Box p={0} sx={{ 
            flex: '1 1 auto',
            borderLeftWidth: `1px`,
            // borderLeftColor: `#1a202c`,
            background: `rgba(95,108,128,0.05)`, // secondary
            borderLeftColor: `divider`,
            borderLeftStyle: `dashed`,
            padding: 32 }}>
          <Text sx={{ fontSize: [1, 1, 2], fontStyle: "italic" }}>
            Written in {year} by {siteTitle}, who lives in Sitges, currently pursuing a double degree in Mathematics and Computer Science at University of Barcelona.
          </Text>
          </Box>
        </Flex>
      <Divider sx={{
        borderBottomStyle: `solid`,
        backgroundImage: `linear-gradient(90deg,#fc5c7d,#6a82fb)`,
        borderColor: `rgba(0,0,0,0)`,
      }}/>
    </div>
  )
}

export default PostFooter
